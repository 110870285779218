<template>
  <div>
    <v-card-text>
      <v-row>

        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            ref="codigo_comercio"
            v-model=dataTef.codigo_comercio
            label="Codigo_Comercio"
            prepend-inner-icon="mdi-account-tie"
            required
          />
        </v-col>
        <v-col
          cols="12"
          sm="6"
        >
          <v-text-field
            ref="codigo_terminal"
            v-model=dataTef.codigo_terminal
            label="Codigo_Terminal"
            prepend-inner-icon="mdi-account-tie"
            required
          />
        </v-col>
      </v-row>
      <v-card-actions class="justify-end">

        <v-btn
          text
          style="background-color:rgb(204 33 40);color:white !important"
          @click="updateDataTef()"
        >
          Actualizar
        </v-btn>
      </v-card-actions>
    </v-card-text>

  </div>
</template>

<script>
  export default {
    name: 'campoCredibancoApps',
    props: ['dataTef', 'token_key', 'code_store'],

    data () {
      return {
        dataUpdateTef: [],
      }
    },
    methods: {

      async updateDataTef () {
        this.dataUpdateTef.dataCredentials = this.dataTef
        this.dataUpdateTef.token_key = this.token_key
        this.dataUpdateTef.code_store = this.code_store
        this.dataUpdateTef.user = JSON.parse(atob(localStorage.getItem('uid'))).user
        this.dataUpdateTef.observation = 'Updated Credentials'
        await this.$store.dispatch('credentialsTef/updateCredentials', this.dataUpdateTef)
      },
    },
  }
</script>

<style scoped>

</style>
